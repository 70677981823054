@import "styles/vars";

.account--inactive {
  background-color: lighten(red, 47);
}

button.activateDeactivateAccount {
  background: $primaryLight;
}

.covidScreeningResult {
  display: flex;
  gap: 2rem;
  .cardIcon {
    width: 40px;
    margin-right: 0rem;
    border-radius: 4px 0 0 4px;
  }
}

.covidScreeningResult--PASS {
  .cardIcon {
    color: darkgreen;
  }
}
.covidScreeningResult--FAIL {
  .cardIcon {
    color: darkred;
  }
}
.covidScreeningResult--UNKNOWN {
  .cardIcon {
    color: $primaryDark;
  }
}

.dailyCovidScreeningResult--PASS {
  background-color: lighten(green, 70);
  .cardIcon {
    background-color: lighten(green, 60);
  }
}
.dailyCovidScreeningResult--FAIL {
  background-color: lighten(red, 30);
  .cardIcon {
    background-color: lighten(red, 20);
  }
}
.dailyCovidScreeningResult--UNKNOWN {
  background-color: lightskyblue;
  .cardIcon {
    background-color: darken(lightskyblue, 10);
  }
}

.cancelRegistrationButton {
  text-decoration: underline;
  color: red;
}
.cancelRegistrationButton:hover {
  color: darken(red, 20);
}
.registrationIsCancelled {
  color: red;
}

@media print {
  #root {
    display: none;
  }
  .modalContainer--print {
    display: block;
    max-width: 8.5in;
    margin: 0 auto;

    .receiptHeader {
      // margin-top: .5in;
      margin-bottom: 0.5in;

      .logo {
        margin-bottom: 0.4in;
      }

      h1 {
        margin: 6px 0 0 0;
        margin-bottom: 0.5in;
        color: rgba(0, 0, 0, 0.4);
      }

      // .receiptHeaderCell {

      // }

      .receiptHeaderCell--left {
        p {
          margin: 0;
          font-size: 10pt;
        }
      }

      .receiptHeaderCell--right {
        text-align: right !important;
        table {
          width: 100%;
          border-collapse: collapse;

          tr {
            th {
              padding: 0.3rem;
            }
            td {
              padding: 0.3rem 0;
            }
          }
        }
      }
    }

    .logo {
      width: 2in;
    }
  }
}

@import "styles/vars";

.card {
  box-sizing: border-box;

  border-radius: $borderRadius;
  padding: 1.2rem 2rem;
  // box-shadow: 0 1px 4px rgba(0,0,0,0.15);
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  margin: 2rem 0;
  display: block;
  text-decoration: none;
  color: $defaultText;
  transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1),
    width 235ms cubic-bezier(0.4, 0, 0.2, 1);
  background: none;
  border: none;
  outline: none;
  position: relative;

  .cardDate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: $primaryLight;
    border-radius: $borderRadius 0 0 $borderRadius;
    width: 5.6rem;
    color: $primaryWhite;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    padding: 1.7rem 0;
    letter-spacing: 0.02em;
  }

  .cardDate--future {
    background: $primaryDisabled;
  }
  .cardDate--present {
    background: $primaryLight;
  }
  .cardDate--past {
    background: $successColor;
  }

  .cardProfile {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: lighten($primaryDisabled, 30);
    border-radius: $borderRadius 0 0 $borderRadius;
    width: 5.6rem;
    color: darken($primaryDisabled, 10);
    text-align: center;
    font-size: 2.2rem;
    padding: 1.7rem 0;
    // letter-spacing: 0.02em;
  }

  .cardProfile--male {
    background: lighten($primaryMale, 44);
    color: darken($primaryMale, 5);
  }

  .cardProfile--female {
    background: lighten($primaryFemale, 35);
    color: darken($primaryFemale, 20);
  }

  .cardSection {
    .cardTitle {
      font-size: 1.2rem;
      letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 0.2rem;
    }

    color: $defaultText;
    text-decoration: none;
    font-size: 1.8rem;
  }

  .cardSection--right {
    text-align: right;
  }
}

.card.card--indent {
  padding-left: 7.6rem;
}
.card.card--deleted .cardSection {
  color: red;
}
.card.card--deleted .cardProfile {
background-color:lighten(red,35);
color: red;
}

a.card,
button.card,
.card--clickable {
  display: block;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

a.card:hover,
button.card:hover {
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 4px 12px 1px rgba(60, 64, 67, 0.16);
}

.cardCapacityContainer {
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // background: rgba(0,0,0,0.1);
  border-radius: 0 0 $borderRadius $borderRadius;
  overflow: hidden;
}

.cardCapacity {
  height: 100%;
}

.cardCapacity--red {
  background: $errorColor;
}
.cardCapacity--orange {
  background: #df6b00;
}
.cardCapacity--yellow {
  background: $warningColor;
}
.cardCapacity--green {
  background: $successColor;
}

.cardLine {
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background: $primaryDisabled;
  border-radius: $borderRadius 0 0 $borderRadius;
  z-index: 1;
}

.cardLine--error {
  background: $errorColor;
}

.cardLine--success {
  background: $successColor;
}

.cardHR {
  border-bottom: 1px solid #eee;
  margin: 2rem -2rem;
}

.cardIcon {
  box-sizing: border-box;
  margin: -1.2rem 2rem -1.2rem -2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
}

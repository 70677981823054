@import "styles/vars.scss";

.button {
  width: 100%;
  border: none;
  padding: 1.8rem 3rem 1.7rem 3rem;
  font-size: 1.5rem;
  border-radius: $borderRadius;
  background: $primaryLight !important;
  color: $primaryWhite;
  letter-spacing: 1px;
  line-height: 24px;
  margin: 1rem 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  box-sizing: border-box;
  i {
    vertical-align: bottom;
    margin-right: 1rem;
  }
}

button.button:hover {
  background: darken($primaryLight, 10) !important;
}

button.button--material {
  width: 4.8rem;
  height: 4.8rem;
  padding: 0px;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18),
    0 4px 8px 1px rgba(60, 64, 67, 0.46);
  text-align: center;
  i {
    line-height: 4.8rem;
    margin: 0px;
  }
  background-color: $primaryLight !important;
}

.button--unstyled {
  width: auto;
  background: none;
  color: $primaryDark;
  padding: 1rem;
  border-radius: 50%;
  i {
    margin: 0px;
  }
}

.button--unstyled:hover {
  background: $primaryGray;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.breadcrumbContainer {
  height: 100%;
  position: relative;
  float: left;
  text-decoration: none;
}
.breadcrumbContainer .breadcrumb {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 2rem;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.75);
}
.breadcrumbContainer .breadcrumb .breadcrumbTitle {
  font-size: 1rem;
  text-transform: uppercase;
  color: #449cff;
  margin-bottom: .4rem;
  font-weight: bold;
}
.breadcrumbContainer:not(:first-child):not(.breadcrumbContainer--right)::before {
  content: '';
  background: #f6f6f6;
  float: left;
  height: 40px;
  width: 1px;
  margin: 2rem 0;
}
.breadcrumbContainer--right {
  text-align: right;
  float: right;
}
.breadcrumbContainer--right:not(:last-child)::before {
  content: '';
  background: #f6f6f6;
  float: right;
  height: 40px;
  width: 1px;
  margin: 2rem 0;
}

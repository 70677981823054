@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
header {
  z-index: 100;
  position: fixed;
  background: #002146;
  color: rgba(255, 255, 255, 0.95);
  min-height: 60px;
}
header .logo {
  text-align: center;
  padding: 1.6rem 0;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #c4e0ff;
  font-weight: bold;
  font-size: 1.1rem;
}
header .logo i {
  display: block;
  color: #0074F7;
  transform: rotate(-90deg);
  font-size: 36px;
}
header .headerLine {
  background: rgba(255, 255, 255, 0.05);
  height: 1px;
}
header .hamburger {
  display: none;
}
nav {
  height: 100%;
  position: relative;
}
nav .navBottom {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
}
nav a,
nav .button--link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.95);
  padding: 2rem 2rem;
  line-height: 24px;
  font-size: 1.5rem;
  letter-spacing: 0.04em;
  border-left: 2px solid #002146;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  display: block;
}
nav a i,
nav .button--link i {
  vertical-align: bottom;
  margin-right: 1rem;
  color: #0074F7;
  opacity: 0.8;
}
nav a:hover,
nav .button--link:hover {
  background: rgba(0, 0, 0, 0.2);
  border-color: #0074F7;
}
nav .button--link {
  width: 100%;
  text-align: left;
  position: relative;
}
nav .button--link .notificationBubble {
  background: #D40000;
  border-radius: 50%;
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: .8rem;
  text-align: center;
  line-height: 14px;
  top: 14px;
  left: 34px;
}
nav a.current {
  background: #002d60;
}
@media screen and (max-width: 47.938em) {
  .headerContainer {
    height: 60px;
  }
  header {
    left: 0;
    right: 0;
  }
  header .logo {
    float: left;
    padding: 0 2rem;
  }
  header .logo i {
    font-size: 24px;
    line-height: 60px;
  }
  header .hamburger {
    float: right;
    display: block;
    margin: 7px 1rem 7px 0;
    background: none !important;
  }
  header .hamburger i {
    color: #fff;
  }
  nav {
    background: #002146;
    position: fixed;
    top: 60px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    transform: translateX(100%);
    transition: all 100ms linear;
    display: none;
  }
  nav a {
    padding: 2rem 1.5rem;
  }
  nav.nav--expanded {
    transform: translateX(0%);
    display: block;
  }
}
@media screen and (min-width: 48em) {
  header {
    top: 0;
    height: 100vh;
    width: 28rem;
  }
}

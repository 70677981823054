@import "styles/vars";

.deleteDevice {
  outline: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  i {
    line-height: 36px;
    vertical-align: bottom;
    color: rgba(0, 0, 0, 0.75);
  }
}

.deleteDevice:hover {
  background-color: rgba(0, 0, 0, 0.1);
  i {
    color: red;
  }
}

.deviceGrid {
  display: grid;
  grid-template-columns: 60px 200px 160px 1fr 1fr 36px;
}

@media only screen and (max-width: $md) {
  .deviceGrid {
    grid-template-columns: 60px auto 26px;
    .mobileHidden {
      display: none;
    }
  }
}

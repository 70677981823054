@import "styles/vars";

.personContainer {
    height: 100%;

    .personProfile {
        grid-area: profile;
        text-align: center;
        padding: 2rem;

        button {
            margin: 3rem 0;
        }

        .personProfilePicture {
            width: 12.8rem;
            height: 12.8rem;
            margin: 0 auto;
            background: rgba(0,0,0,0.05);
            border-radius: 50%;
            font-size: 4.8rem;
            line-height: 12.8rem;
            color: rgba(0,0,0,0.1);
            margin-bottom: 2rem;
            border: 2px solid rgba(0,0,0,0.2);
            box-shadow: 
                0 1px 1px 0 rgba(60,64,67,.08),
                0 1px 3px 1px rgba(60,64,67,.16)
            ;
        }
        .personProfilePicture.gender--male {
            border-color: $primaryLight;
        }
        .personProfilePicture.gender--female {
            border-color: $primaryFemale;
        }

        .personName {
            font-size: 2.4rem;
        }

        .personDetails {
            font-size: 1.6rem;
            margin-top: .3rem;
            span:not(.gender) {
                margin: 0 .5rem;
                color: rgba(0, 0, 0, 0.2);
            }
            // span.gender--male {
            //     color: $primaryLight;
            // }
            // span.gender--female {
            //     color: #FF31DE;
            // }
        }

        .personEmail {
            margin-top: 2rem;
        }

        .personAddress {
            font-size: 1.6rem;
            margin-top: 2rem;
        }

        .personLink {
            margin-top: 2rem;
        }

    }
    .personMain {
        grid-area: main;
        position: relative;
    }

}

.timeline {
    width: 4px;
    height: 40px;
    background: $primaryGray;
    margin: -20px 0 -20px 26px;
}

.timelineYear {
    width: 90px;
    height: 0px;
    // background: $primaryGray;
    border-top: 1px solid $primaryGray;
    border-bottom: 1px solid $primaryGray;
    margin: 18px 0 20px 30px;
    position: relative;

    .year {
        background: #E5E5E5;
        display: inline-block;
        padding: 0 .8rem;
        border-radius: $borderRadius;
        position: absolute;
        right: 0;
        top: -12px;
        bottom: -12px;
        line-height: 2.4rem;
        color: rgba(0,0,0,0.55);
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 0.02em;
    }
}

.timeline--past,
.timelineYear--past,
.timelineYear--past .year {
    background-color: lighten($successColor, 55);
    border-color: lighten($successColor, 55);
    color: darken($successColor, 10);
}

.timeline--present,
.timelineYear--present,
.timelineYear--present .year {
    background-color: lighten($primaryLight, 40);
    border-color: lighten($primaryLight, 40);
    color: darken($primaryMale, 10);
}

@media screen and (min-width:48em) {

    .personContainer {
        display: grid;
        grid-template-areas: "main profile";
        grid-template-columns: auto 320px;
        grid-gap: 2rem;
        margin-right: -2rem;

        .personProfile {
            border-left: 1px solid #f6f6f6;
        }
    }

}
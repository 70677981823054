@import "vars";

html {
  font-size: 62.5%;
}

root,
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: helvetica, arial, verdana, sans-serif;
  font-size: 1.4rem;
  color: $defaultText;
  letter-spacing: 0.02em;
}

h1 {
  color: lighten($primaryDark, 20);
}

.container-default {
  height: 100%;
}

.container--refreshMargin {
  margin-top: 40px;
  .headerContainer header {
    padding-top: 40px;
  }
}

.container--blur {
  filter: blur(1px);
  overflow: hidden;
}

.npsScore.dashboard {
  text-align: center;
  font-size: 8rem;
  padding: 10rem 0;
  h1 {
    font-size: 4rem;
  }
}

@media screen and (min-width: 48em) {
  // body {
  //     overflow: hidden;
  // }
  .container-default {
    display: grid;
    grid-template-columns: 28rem auto;
  }
}

.table {
  margin: 2rem -2rem;
  border-collapse: collapse;
  display: grid;
  min-width: 100%;
  box-sizing: border-box;
}
.table .tableHead {
  display: contents;
}
.table .tableHead .tableRow .tableColumn--header {
  position: sticky;
  top: 0;
}
.table .tableBody {
  display: contents;
}
.table .tableBody .tableRow:nth-child(odd) td {
  background: #fafafa;
}
.table .tableFoot {
  display: contents;
  align-items: end;
  text-align: right;
}
.table .tableRow {
  display: contents;
}
.table .tableRow .tableColumn {
  padding: 1.5rem 1rem;
}
.table .tableRowDivider {
  display: contents;
}
.table .tableColumn:first-of-type {
  padding-left: 2rem;
}
.table .tableColumn:last-of-type,
.table .tableColumn--last {
  padding-right: 2rem !important;
}
.table .tableColumn--borderTop {
  border-top: 1px solid #eee;
}
.table .alignRight {
  text-align: right;
}
.table .right--pad {
  padding-right: 2rem !important;
}
@media print {
  .tableBody .tableRow:last-of-type .tableColumn {
    border-bottom: 1px solid #eee;
  }
  .tableColumn--header {
    border-bottom: 1px solid #eee;
    position: relative !important;
  }
  .tableRowDivider td {
    border-bottom: 1px solid #eee;
  }
}

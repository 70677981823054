@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
main {
  padding: 2rem;
  position: relative;
}
#router {
  height: 100%;
}
.layoutHeaderContainer {
  height: 60px;
}
.layoutHeader {
  position: fixed;
  height: 80px;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #f6f6f6;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -2rem;
}
@media screen and (max-width: 47.938em) {
  .layoutHeader {
    left: 2rem;
    right: 2rem;
  }
}
@media screen and (min-width: 48em) {
  .layoutHeader {
    left: 30rem;
    right: 2rem;
  }
}

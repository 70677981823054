@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.modalContainer .modal {
  min-width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50.1%);
  background: #fff;
  border-radius: 5px;
  padding: 2rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18), 0 4px 12px 1px rgba(60, 64, 67, 0.26);
}
.modalContainer .modal .modalClose {
  position: absolute;
  right: -22px;
  top: -32px;
  background: #eee;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 4px 12px 1px rgba(60, 64, 67, 0.26);
}
.modalContainer .modal .modalClose:hover {
  background: #d5d5d5;
}
.modalContainer .modal .modalHeader {
  border-bottom: 1px solid #fafafa;
  text-align: center;
  padding: 2rem 0;
  margin: -2rem -2rem 0 -2rem;
  border-radius: 5px 5px 0 0;
}
.modalContainer .modal .modalHeader i {
  background: #eee;
  color: #002146;
  padding: 1rem;
  border-radius: 50%;
  margin-bottom: 1.5rem;
}
.modalContainer .modal .modalTitleContainer {
  font-size: 1.8rem;
}
.modalContainer .modal .modalTitleContainer .modalTitle {
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0.2rem;
}
.modalContainer--print {
  background: #fff;
}
@media screen {
  .modalContainer--print {
    display: none;
  }
}
@media print {
  #root {
    display: none;
  }
  .modalContainer--print {
    display: block;
    position: relative;
  }
}

@media screen and (min-width: 48em) {
  .scrollContainer {
    overflow-y: auto;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 2rem;
  }
}

.registerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    max-height: 100%;
    overflow-y: auto;
    padding: 2rem;
}
.notes
    outline: none
    appearance: none
    border: none
    background: none
    float: right
    padding: 0 2rem
    color: rgba(0,0,0,0.4)
    cursor: pointer
    position: relative

    .notesCountBubble
        position: absolute
        top: 25px
        right: 15px
        background: #449cff
        border-radius: 50%
        width: 12px
        height: 12px
        font-size: 7pt
        line-height: 12px
        text-align: center
        color: #fff

    i
        line-height: 80px

.notes:hover
    color: rgba(0,0,0,0.6)

.noteContainer
    margin: 1rem 0
    height: 40px
    .material-icons
        border-radius: 50%
        float: left
        height: 40px
        width: 40px
        background: #eeeeee
        text-align: center
        line-height: 40px
        color: rgba(0,0,0,0.3)
    .profileImage
        border-radius: 50%
        float: left
    .note
        padding-left: 5rem

        .noteUser
            font-weight: bold
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.aside {
  width: 100%;
}
.asideDetail {
  margin: 5rem 0;
}
.asideDetail .asideDetail__title {
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #0074F7;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
  font-weight: bold;
}
.asideDetail .asideDetail__value {
  font-size: 2.4rem;
}
@media screen and (max-width: 47.938em) {
  .asideDetail {
    display: inline-block;
    margin: 5rem 2.5rem;
  }
}

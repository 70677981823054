@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.currencyHighlight--negative {
  color: #D40000;
}
.npsRating {
  font-size: 3rem;
}
.npsRating--promoter {
  color: #00A006;
}
.npsRating--passive {
  color: #FF9900;
}
.npsRating--detractor {
  color: #D40000;
}

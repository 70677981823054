@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.subNavContainer {
  margin: 1rem 0;
}
.subNavContainer a {
  display: inline-block;
  padding: 1rem 0;
  margin-right: 3rem;
  text-decoration: none;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.3rem;
}
.subNavContainer a.current {
  color: #002146;
  border-bottom: 2px solid #0074F7;
}

// $import url('https://yarnpkg.com/en/package/normalize.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$primaryDark: #002146;
$primaryLight: #0074F7;
$primaryWhite: rgba(255,255,255,0.95);
$defaultText: rgba(0,0,0,0.75);
$primaryDisabled: #a5a5a5;
$primaryGray: rgba(0,0,0,0.1);

$primaryMale: $primaryLight;
$primaryFemale: #FF31DE;

$errorColor: #D40000;
$successColor: #00A006;
$warningColor: #FF9900;

$borderRadius: 5px;

$padding: 2rem;

$sm: 35.5em;
$md: 48em;
$lg: 64em;
$xl: 80em;
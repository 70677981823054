.grid {
  display: grid;
  grid-gap: 2rem;
}
.grid-1-1 {
  grid-template-columns: 1fr 1fr;
}
.grid-1-1-1 {
  grid-template-columns: 1fr 1fr 1fr;
}

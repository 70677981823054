@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf)
    format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.inputContainer {
  height: 5.4rem;
  position: relative;
  margin: 2rem 0;
}
.inputContainer .react-calendar {
  position: relative;
  z-index: 1;
}
.inputContainer .inputBorderContainer,
.inputContainer .inputBorder--left,
.inputContainer .inputBorder--middle,
.inputContainer .inputBorder--right {
  pointer-events: none;
}
.inputContainer .inputBorderContainer {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}
.inputContainer .inputBorder--left {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 0 0 5px;
  height: 100%;
  width: 1.1rem;
  float: left;
}
.inputContainer .inputBorder--middle {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: auto;
  height: 100%;
  flex: 0 0 auto;
  max-width: calc(100% - 12px * 2);
}
.inputContainer .inputBorder--right {
  flex-grow: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  border-radius: 0 5px 5px 0;
}
.inputContainer .errorMessage {
  position: absolute;
  bottom: 1px;
  right: 2px;
  font-size: 1.1rem;
  color: red;
}
.inputContainer label {
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 4px;
  cursor: text;
  font-size: 1.4rem;
  transition: all 100ms;
  max-width: 100%;
  display: inline-block;
}
.inputContainer input {
  border: none;
  outline: none;
  background: none;
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  top: 1px;
  text-indent: 1.5rem;
  appearance: none;
}
.inputContainer select {
  border: none;
  outline: none;
  background: none;
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  padding: 0 1.5rem;
  top: 1px;
  appearance: none;
}
.inputContainer textarea {
  border: none;
  outline: none;
  background: none;
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  padding: 1.5rem;
  top: 1px;
  appearance: none;
}
.inputContainer--search {
  height: 4rem;
}
.inputContainer--search .inputBorder--left {
  border-radius: 20px 0 0 20px;
  width: 2rem;
}
.inputContainer--search .inputBorder--right {
  border-radius: 0 20px 20px 0;
}
.inputContainer.input--focused .inputBorder--middle,
.inputContainer.input--filled .inputBorder--middle {
  border-top: none;
  padding-top: 1px;
}
.inputContainer.input--focused label,
.inputContainer.input--filled label {
  font-size: 1.3rem;
  top: -2px;
  color: rgba(0, 0, 0, 0.5);
}
.inputContainer.input--focused label {
  color: #0074f7;
}
.inputContainer.input--focused .inputBorder--left,
.inputContainer.input--focused .inputBorder--middle,
.inputContainer.input--focused .inputBorder--right {
  border-color: #0074f7;
}
.inputContainer--search.input--focused .label,
.inputContainer--search.input--filled .label {
  display: none;
}
.inputContainer:not(.input--focused):hover .inputBorder--left,
.inputContainer:not(.input--focused):hover .inputBorder--middle,
.inputContainer:not(.input--focused):hover .inputBorder--right {
  border-color: rgba(0, 0, 0, 0.2);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}
button[type="submit"].submit {
  width: 100%;
  border: none;
  padding: 1.8rem 3rem 1.7rem 3rem;
  font-size: 1.8rem;
  border-radius: 5px;
  background: #002146;
  color: rgba(255, 255, 255, 0.95);
  letter-spacing: 1px;
  margin: 1rem 0;
  cursor: pointer;
  outline: none;
}
button[type="submit"].submit:hover {
  background: #003979;
}
button[type="submit"].submit:disabled {
  background: #a5a5a5;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
fieldset legend {
  background: #0074f7;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.95);
}
fieldset.card {
  padding: 0 2rem 0 2rem;
}

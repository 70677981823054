@import "styles/vars";

.loginContainer {
    position: relative;
    height: 100%;

    h1, h2, h3, h4 {
        color: lighten($primaryDark, 20);
    }

    .login {
        display: border-box;
        width: 100%;
        max-width: 480px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .logo {
            text-align: center;
            padding: 1.6rem 0;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            color: lighten($primaryDark, 20);
            font-weight: bold;
            font-size: 1.4rem;
            margin-bottom: 4rem;
            i {
                display: block;
                font-size: 48px;
                color: $primaryDark;
                transform: rotate(-90deg);
                margin-bottom: .4rem;
            }
        }

        .padding {
            padding: 2rem;
        }

    }

}
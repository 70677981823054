@import 'styles/vars';

.weekContainer {
    display: flex;
    gap: 1rem;

    .weekButton {
        appearance:none;
        outline:none;
        background: none;
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: 4px;
        cursor:pointer;
    }
    .weekButton:hover {
        background: #eee;
    }
    .weekButton.selected {
        background: $primaryLight;
        color: $primaryWhite;
        border-color: $primaryLight;
    }
}

.weekCountContainer {
    padding: 2rem 0 0 0;
    display: flex;
    gap: 2rem;

    .needsCreditsApplied {
        color: red;
    }
}